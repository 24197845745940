import React, {useEffect} from "react";
import { Router, Route, Switch} from 'react-router-dom';
import Notifications from 'react-notify-toast';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import {Login} from './components/Login/Login';
import Dashboard from "./components/Dashboard/Dashboard";
import Navigation from "./components/Navigation/Navigation";
import useProfile from './components/Auth/useProfile';

function App() {
  const history = useHistory();
  const { userProfile, setUserProfile } = useProfile();

  useEffect(() => {
    // change background color with a login screen
    document.body.style.background = !_.isUndefined(userProfile) && !_.isNull(useProfile) && !_.isEmpty(userProfile) ? '#F5F5F5' : '#0C1116';
  });
  
    return (
      <main>
        <div className="wrapper">
            <Notifications />
         
            <div className="main">
            {(!_.isUndefined(userProfile) &&  !_.isNull(userProfile) && !_.isEmpty(userProfile)) && 
              <Navigation setUserProfile={setUserProfile} />
             } 
              <div className="content">
              <Router history={history}>
                <Switch>
                  <Route exact path="/">
                    <Login setUserProfile={setUserProfile}/>
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard setUserProfile={setUserProfile}/>
                  </Route>
                </Switch>
              </Router>
              </div>
            </div>
        </div>
      </main>
    );
}

export default App;
