// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// // dev firebase details
// const firebaseConfig = {
//     apiKey: "AIzaSyCzqI5TlxhpQI5hwEIN8TyUAqEn8mCmPwE",
//     authDomain: "fantasylife-dev.firebaseapp.com",
//     databaseURL: "https://fantasylife-dev.firebaseio.com",
//     projectId: "fantasylife-dev",
//     storageBucket: "fantasylife-dev.appspot.com",
//     messagingSenderId: "836462718134",
//     appId: "1:836462718134:android:cbaa30b41192ac62",
//     // measurementId: "G-PBWZ5N2744"
//   };

//production firebase Config
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL:  process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGET_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
} 

// // testing firebase Config
// const firebaseConfig = {
//   apiKey: "AIzaSyDTLy6Owtk5l7RzivFhJqHccIbV2IoRsIo",
//   authDomain: "betmgm-8174c.firebaseapp.com",
//   databaseURL: "https://betmgm-8174c-default-rtdb.firebaseio.com",
//   projectId: "betmgm-8174c",
//   storageBucket: "betmgm-8174c.appspot.com",
//   messagingSenderId: "846176554101",
//   appId: "1:846176554101:web:3f41047977e3971fbd7c3c",
//   measurementId: "G-PBWZ5N2744"
// };

  // Initialize Firebase
  export default initializeApp(firebaseConfig);
