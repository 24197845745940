import React, {useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {notify} from 'react-notify-toast';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import useProfile from '../Auth/useProfile';


async function loginUser(credentials) {
  return fetch(`${process.env.REACT_APP_NODE_API}/api/users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  .then((response) => response.json())
  .then(responseJson => {
    if ((responseJson.status !== 'success') || (responseJson.data.length === 0)) {
      notify.show(responseJson.message, "error", 5000);
    } 
    return responseJson;
  
  })
  .catch((error) => {
    console.log(error)
    notify.show(error, "error", 5000);
  });
}
 

export function Login({ setUserProfile }) {
  const history = useHistory();
  const { userProfile } = useProfile();
  const [viewPassword, setViewPassword] = useState(false);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [channel, setChannel] = useState(process.env.REACT_APP_CHANNEL);

  useEffect(() => {
    
    if(!_.isUndefined(userProfile) && !_.isNull(useProfile) && !_.isEmpty(userProfile)) {
      history.push('/dashboard');
    } 
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
      channel
    });
    setUserProfile(response.data);
    history.push('/dashboard');
  }

  const showPassword = event => {
    // 👇️ toggle navbar state on click
    setViewPassword(current => !current);
  }

  return (
    <div id="login-section" className="d-flex align-items-center py-5">
        <div className="form-signin w-100 m-auto p-3 py-5">
          <form onSubmit={handleSubmit}> 
            <div className="text-center py-5">
              <img className="mb-4 mt-3" src="/images/betsperts-logo.png" alt="" />
            </div>
            <div className="heading pb-4">Welcome to Admin Dashboard!</div>
            <div className="mb-4">
              <label className="form-label">Username</label>
              <input type="text" className="form-control" id="username" value={username} placeholder="Enter Your Username" required onChange={e => setUserName(e.target.value)}/>
            </div>
            <div className="mb-4 position-relative">
              <label className="form-label">Password</label>
              <input type={viewPassword ? "text" : "password"} className="form-control" id="password" value={password} placeholder="Enter Your Password" required onChange={e => setPassword(e.target.value)}/>
              <img src={"/images/icons/" + (viewPassword ? 'eye-close.png' : 'eye.png') } className="password-view" alt="password" onClick={showPassword}/>
            </div>
            <button type="submit" className={"mt-2 btn btn-primary btn-login " + ((!_.isNull(username) && (!_.isUndefined(password) && !_.isNull(password) && password.length >= 8)) ? '' : 'disabled')}>Login</button>
          </form>
        </div>
    </div>
  );
}

Login.propTypes = {
  setUserProfile: PropTypes.func.isRequired
}