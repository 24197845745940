import React from 'react'
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import useProfile from '../Auth/useProfile';
import Dropdown from 'react-bootstrap/Dropdown';


export default function Nav(props) {
    const history = useHistory();
    const { userProfile } = useProfile();

    const getUserLogout = async function () {
        return fetch(`${process.env.REACT_APP_NODE_API}/api/users/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': !_.isUndefined(userProfile) && !_.isNull(userProfile) ? `Bearer ${userProfile.token}` : null,
          },
        })
        .then((response) => response.json())
        .then(responseJson => {
            console.log('ssss');

            if (responseJson.status === 'success') {
              props.setUserProfile(null)
              localStorage.clear();
              history.push ('/');
            } else {
              //notify.show(responseJson.message, "error", 5000);
              props.setUserProfile(null)
              localStorage.clear();
              history.push ('/');
            }
    
        })
        .catch( (error) => {
          console.log(error)
        })
      } 
    return (
        <nav className="navbar navbar-expand bg-white">
            <div className="d-flex page-title">
                <img className="img-fluid dasboard-logo" src="/images/bt-logo.png" alt="betmgm-logo" />
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={props.handleClick}>
            <span className="navbar-toggler-icon"></span>
            </button>
            {(window.location.pathname).includes("news") ?
            <a className="back-icon" href="/dashboard"><img src="/images/icons/back.svg" alt="back-icon" /></a>
            :
                <form className="d-smm-inline-block d-none">
                    <div className="input-group-navbar input-group">
                        <input placeholder="Search" aria-label="Search" className="form-control" />
                        <button type="button" className="btn" >
                        <img src="/images/icons/ic-search.png" className="me-2" alt="search" />
                        </button>
                    </div>  
                </form>
            }
            <div className="navbar-collapse collapse">
            <div className="navbar-align navbar-nav user-dropdown">
                
                <Dropdown>
                    <Dropdown.Toggle >
                        <span className="user-title">Administrator</span>
                        <img src="/images/avatar-icon.png" className="user-avatar" alt="user-avatar"/>                    
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => getUserLogout()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </div>
        </nav>
    )
}    