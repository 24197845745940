import React,  { useState, useEffect, Fragment, useRef} from 'react';
import Select from 'react-select'
import _ from 'lodash';
import Loader from "../Loader/Loader";
import moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import useSound from 'use-sound';
import Switch from "react-switch";
import { getDatabase, ref, onChildAdded, onChildChanged, off, limitToLast, query} from "firebase/database";
import initializeApp from '../Firebase/Config';
import useProfile from '../Auth/useProfile';
import minorfx from '../../sounds/hotmail_new_alert.mp3';
import majorfx from '../../sounds/iphone_ding.mp3';

export default function Dashboard(props) {
  const history = useHistory();
  const { userProfile } = useProfile();
  const [isLoading, setIsLoading] = useState(false)
  const [teamOptions, setTeamOptions] = useState(null);
  const [newsRecords, setNewsRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filterEnable, setFilterEnable] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [alertSettings, setAlertSettings] = useState(null);
  const [breakingAlert, setBreakingAlert] = useState(false);
  const [minorAlert, setMinorAlert] = useState(false);
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [indexRecordCount, setIndexRecordCount] = useState(0)
  var child_added_first = true;

  const refbreaking = useRef(null);
  const refMinor = useRef(null);

  // Initialize Firebase Authentication and get a reference to the service
  const db = getDatabase();
  const dbRefPath = 'mgm-news';
  const dbRef1 = query(ref(db, dbRefPath), limitToLast(1));

  const dbRef2 = ref(getDatabase(initializeApp), dbRefPath);

  useEffect(() => {
    if(_.isUndefined(userProfile) || _.isNull(userProfile) || _.isEmpty(userProfile)) {
      history.push('/');
    } 

    getAlertSettings();
    getAllNews();
    getAllTeams();
   
  }, []);

  useEffect(() => {
    
  }, [])

  useEffect(() => {
      onChildAdded(dbRef1, (snap) => {
        if (!child_added_first) {
          console.log('new record added====>', snap.val())
          let typeOfNews = snap.val();
          if (!_.isNull(newsRecords) && typeOfNews.type === 'minor' && !_.isNull(alertSettings) && !_.isUndefined(alertSettings.minor_alert) && alertSettings.minor_alert) { 
            setMinorAlert(true);
          } else if (!_.isNull(newsRecords) && typeOfNews.type === 'breaking' && !_.isNull(alertSettings) && !_.isUndefined(alertSettings.breaking_alert) && alertSettings.breaking_alert) {
            setBreakingAlert(true);
          }
        }   
        child_added_first = false; 
        console.log(child_added_first);
      });
    

    onChildChanged(dbRef2, (snap) => {
      console.log('changed-->',snap.key, alertSettings)
      let typeOfNews = snap.val();
      if (typeOfNews.type === 'minor' && !_.isNull(alertSettings) && !_.isUndefined(alertSettings.minor_alert) && alertSettings.minor_alert) {
        setMinorAlert(true);
      } else if (typeOfNews.type === 'breaking' && !_.isNull(alertSettings) && !_.isUndefined(alertSettings.breaking_alert) && alertSettings.breaking_alert) {
        setBreakingAlert(true);
      }
    });
 
    return () => off(dbRef2, 'child_added');
    return () => off(dbRef2, 'child_changed');
  }, [alertSettings])

  useEffect(() => {
    if (breakingAlert)
      refbreaking.current.click();

    setBreakingAlert(false);
    getAllNews();   
  }, [breakingAlert])  

  useEffect(() => {
    if (minorAlert){
      console.log('minorAlert--->', minorAlert)
      refMinor.current.click();
    }

    setMinorAlert(false) 
    getAllNews(); 
  }, [minorAlert])


  const getAllNews = async () => {
    await fetch(`${process.env.REACT_APP_SOURCE_URL}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {
      method: 'GET',
    })
    .then((res) => {
      if(!res.ok) throw new Error(res.status);
      else return res.json();
    })
    .then((responseJson) => {
        if (_.isEmpty(newsRecords)) {
          setIsLoading(false);
          setNewsRecords(responseJson);
          setIndexRecordCount(responseJson.length)
        } else if(!_.isEmpty(newsRecords) && (JSON.stringify(responseJson) !== JSON.stringify(newsRecords))){
          // setNewsRecords(responseJson);
          let oldArray = newsRecords;
          let newArray = oldArray.concat(responseJson)
          setNewsRecords(newArray);
          setIndexRecordCount(responseJson.length)
        }  
          
    }).catch(error => {
        console.log('ssss,==>', error);
    })
  }

  async function getAllTeams() {
    await fetch(`${process.env.REACT_APP_SPORTS_DATA_API_URL}/Teams?key=${process.env.REACT_APP_SPORTS_DATA_API_KEY}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((res) => res.json())
    .then((responseJson) => {
      if (!_.isNull(responseJson) && !_.isEmpty(responseJson)){
        // setAllteams(responseJson);
        let teamOption = [];
        let allteams = _.orderBy(responseJson, [response => response.Key.toLowerCase()], ['asc']);
        _.map(allteams, (team) => {
          teamOption.push({value: team.TeamID, label: team.Key, key: team.FullName})
        })  
        setTeamOptions(teamOption);
      }      
    }).catch(error => {
        console.log(error);
    })
  }  

  const [playBreakingAlert] = useSound(
    majorfx,
    // { volume: 0.50 }
  );

  const [playMinorAlert] = useSound(
    minorfx,
  );

  const getAlertSettings = async () => {
    var params = {
      'channel': process.env.REACT_APP_CHANNEL
    }
    await fetch(`${process.env.REACT_APP_NODE_API}/api/settings/getAlertStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    })
    .then((res) => res.json())
    .then((responseJson) => {
      if (responseJson.status === 'success' && !_.isEmpty(responseJson.data)) {
        setAlertSettings(responseJson.data)
      }
      
    }).catch(error => {
      console.log('ssss,==>', error);
    })
  }

  const changeAlertStatus =  feature => async event => {
    setIsLoading(true);
    var params = {
      alert_type: (feature === 'breaking') ? 'breaking' : 'minor',
      status: event,
      'channel': process.env.REACT_APP_CHANNEL
    }

  // 👇️ toggle navbar state on click
  await fetch(`${process.env.REACT_APP_NODE_API}/api/settings/setAlertStatus`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })
  .then((res) => res.json())
  .then((responseJson) => {
    if (responseJson.status === 'success') {
      setIsLoading(false);
      setAlertSettings(responseJson.data)
    } 
  }).catch(error => {
    console.log('ssss,==>', error);
  })
} 

  const typeOptions = [
    {label:  'Breaking News', value: 'breaking'},
    {label:  'Minor News', value: 'minor'}
  ]

  useEffect(() => {
    var result = newsRecords
    if ((!_.isUndefined(selectedTeam) && !_.isEmpty(selectedTeam) && !_.isNull(selectedTeam))) {

      result = result.filter(item => (item.team === selectedTeam.label) || (item.team === selectedTeam.key));
      setFilteredRecords(result)
    } 
    
    if (!_.isUndefined(selectedType) && !_.isEmpty(selectedType) && !_.isNull(selectedType)) {
      const filtered_result = result.filter(item => item.type === selectedType.value);
      setFilteredRecords(filtered_result)
    } 

    if (_.isNull(selectedTeam) && _.isNull(selectedType)) {
      setFilteredRecords([])
      setFilterEnable(false)
    } else {
      setFilterEnable(true)
    }

   
  }, [selectedTeam, selectedType]);

  const resetForm = () => {
    setSelectedTeam(null);
    setSelectedType(null);
    setFilterEnable(false);
  }

  const loadMoreRecords = () => {
    setPageIndex(pageIndex+1);
  }

  useEffect(() => {
    // console.log(pageIndex, pageSize)
    getAllNews();
  }, [pageIndex, pageSize])  

  useEffect(() => {
    console.log('indexRecordCount-->', indexRecordCount)
  }, [indexRecordCount]);  

  const newsResult = filterEnable ? filteredRecords : newsRecords
  return (
    <Fragment>
      {
          isLoading &&
          <Loader />
      }

       <div className="container-fluid px-3" id="dashboard-section">
          <div className="row">
            
            <div className="col-12">
              <div className="d-flex align-items-center filter-section mt-3">
                <div className="d-flex align-items-center w-100">
                  <Select 
                    classNames={{
                      control: () =>
                        'react-form-select ms-2',
                      }}  
                    placeholder="Type" 
                    options={typeOptions} 
                    defaultValue={{ label: "Type", value: ''}} 
                    value={selectedType}
                    onChange={(selectedValue) => { !_.isNull(selectedValue) && selectedValue.value !== '' ? setSelectedType(selectedValue) : setSelectedType(null) }} 
                    isClearable={true}
                    styles={{
                      option: (base) => ({
                        ...base,
                        fontSize: 12,
                        height: '100%',
                      }),
                    }}
                  />

                  <Select 
                    classNames={{
                      control: () =>
                        'react-form-select ms-3',
                      }}  
                    placeholder="Team" 
                    options={teamOptions} 
                    defaultValue={{ label: "Team", value: '', key: '' }} 
                    value={selectedTeam}
                    onChange={(selectedValue) => { !_.isNull(selectedValue) && selectedValue.value !== '' ? setSelectedTeam(selectedValue) : setSelectedTeam(null) }} 
                    isClearable={true}
                    styles={{
                      option: (base) => ({
                        ...base,
                        fontSize: 12,
                        height: '100%',
                      }),
                    }}
                  />

                  <div className="ms-3 reset-link" onClick={resetForm}> Reset </div>

                  <div className="ms-auto pe-5"><label className="fw-bolder fs-6">Sounds Alerts </label>
                    <div className="d-flex align-items-center mt-1">
                      <label className="fw-medium">Breaking</label> 
                      <Switch className="ms-2" onChange={changeAlertStatus('breaking')} checked={!_.isNull(alertSettings) && !_.isUndefined(alertSettings.breaking_alert) && alertSettings.breaking_alert} uncheckedIcon={false} checkedIcon={false} height={18} width={30}/>
                      <label className="ms-4 fw-medium">Minor</label>
                      <Switch className="ms-2" onChange={changeAlertStatus('minor')} checked={!_.isNull(alertSettings) && !_.isUndefined(alertSettings.minor_alert) && alertSettings.minor_alert} uncheckedIcon={false} checkedIcon={false} height={18} width={30}/>
                      </div>
                      <div className="d-flex d-none">
                        <button ref={refbreaking} className="btn btn-primary" onClick={playBreakingAlert}>Play Breaking Alert </button>
                        <button ref={refMinor} className="btn btn-primary ms-2" onClick={playMinorAlert}>Play Minor Alert </button>
                      </div>
                  </div> 

                </div>
              </div>
            </div>  

            {  (!_.isUndefined(newsResult) && !_.isEmpty(newsResult) && !_.isNull(newsResult)) ?

              <div className="col-12">
                <div className="table-responsive mt-3 mb-5">
                  <table className="table rounded-table">
                    <thead className="table-light">
                      <tr>
                        <th style={{width: '10%'}}>Date</th>
                        <th style={{width: '10%'}}>Type</th>
                        <th style={{width: '40%'}}>News</th>
                        <th style={{width: '10%'}}>Player Name</th>
                        <th >Position</th>
                        <th >Team</th>
                        <th style={{width: '20%'}}>URL</th>
                      </tr>
                    </thead>
                    <tbody>
                    { 
                      _.map(newsResult, (row, index) => {
                        return (
                          <tr key={index}>
                            <td className={((row.type === 'breaking') ? 'breaking-row' : '')}>{moment(row.timestamp).tz('America/New_York').format("MMM D, h:mm a")}</td>
                            <td className={((row.type === 'breaking') ? 'breaking-row' : '')}>{row.type === 'breaking' ? 'Breaking News' : 'Minor News'}</td>
                            <td className={"text-break " + (row.type === 'breaking' ? 'breaking-row' : '')}>{row.content}</td>
                            <td className={((row.type === 'breaking') ? 'breaking-row' : '')}>{row.name}</td>
                            <td className={((row.type === 'breaking') ? 'breaking-row' : '')}>{row.position}</td>
                            <td className={((row.type === 'breaking') ? 'breaking-row' : '')}>{row.team}</td>
                            <td className={"text-break " + (row.type === 'breaking' ? 'breaking-row' : '')}>{row.url}</td>
                          </tr>
                        )
                      })
                    }  

                    {
                      indexRecordCount >= pageSize &&
                      <tr>
                        <td colSpan="7" className="text-center more-box align-middle"><a className="text-decoration-none fs-6 fw-semibold" onClick={() => { loadMoreRecords() }}> Load More </a></td>
                      </tr>
                    }

                   
                    
                    </tbody>
                  </table>
                </div>
              </div> 

             :
              <div className="py-5 text-center container">
                <div className="row py-lg-5">
                  <div className="col-lg-6 col-md-8 mx-auto empty-box py-5">
                    <img src="/images/icons/gr-no-data-available.png" alt="no-data"/>
                    <div className="heading pt-4 pb-3">No Records Found</div>
                    <div className="box-description">Welcome to Betsperts News Dashboard, currently there is not enough data for overview. You can explore other tabs.</div>
                  </div>
                </div>  
              </div>
            }  
          </div>
      </div>
    </Fragment>  
  );
}